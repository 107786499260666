<template>
  <div class="menu">
    <div v-if="getState() === 'business' && !$store.state.save.success">
      <default
        class="bg"
        v-if="$store.state.business.unsaved.type === 'datepicker'"
      />
      <eventpicker
        class="bg"
        v-else-if="$store.state.business.unsaved.type === 'eventpicker'"
      />
      <services
        class="bg"
        v-else-if="$store.state.business.unsaved.type === 'services'"
      />
      <div v-else class="empty"></div>
    </div>
    <div v-else-if="getState() === 'private' && !$store.state.save.privateUser">
      <private class="bg" />
    </div>
  </div>
</template>
<script>
export default {
  components: {
    default() {
      return import("./default");
    },
    services() {
      return import("./services");
    },
    eventpicker() {
      return import("./eventpicker");
    },
    private() {
      return import("./private");
    }
  },
  props: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {
    getState() {
      return this.$store.state.type;
    }
  }
};
</script>
<style lang="sass" scoped>
@import '@/assets/sass/variables'
.bg
  background-color: $light-grey
.empty
  border-color: white
  height: 30px
  background-color: white
</style>
